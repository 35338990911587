import { Form } from "react-daisyui";
import InputFrame from "./InputFrame";
import { WithContext as ReactTags } from "react-tag-input";
import { useEffect, useState } from "react";
import { convertToUnderScoreVariable } from "../../../utils/ConvertToUnderScoreVariable";

const PropertyTags = ({ name, onChange, defaults = true }) => {
  const defaultTags = defaults
    ? [
        { id: "1", text: "Quartz countertop" },
        { id: "2", text: "HVAC replaced in 2022" },
        { id: "3", text: "Fenced yard" },
        { id: "4", text: "Walk out basement" },
      ]
    : [];
  const [tag, setTag] = useState("");
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [tagsSet, setTagsSet] = useState(new Set());
  const [tags, setTags] = useState(defaultTags);
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  useEffect(() => {
    console.log(tags);

    const target = {
      name: convertToUnderScoreVariable(name),
      value: tags.map((tag) => {
        return tag.text;
      }),
    };
    const event = {
      target: target,
    };
    onChange(event);
  }, [name, tags]);

  const handleInputChange = (tag) => {
    setTag(tag);
    console.log(tag);
    console.log(tagsSet);
    if (tagsSet.has(tag)) {
      setIsDuplicate(true);
    } else {
      setIsDuplicate(false);
    }
  };

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
    setTagsSet(tagsSet.add(tag.id));
    setTag("");
    setIsDuplicate(false);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  return (
    <InputFrame>
      <Form.Label title={name} />
      <p className="px-1 py-2 mt-[-10px] text-xs">
        (ex: "Mention the great schools")
      </p>

      <ReactTags
        handleInputChange={handleInputChange}
        tags={tags}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        handleTagClick={handleTagClick}
        inputFieldPosition="bottom"
        autocomplete={false}
        autofocus={false}
        placeholder="Enter info tags and press enter"
        classNames={{
          tags: "tagsClass",
          tagInput: "tagInputClass",
          tagInputField: "tagInputFieldClass w-full input input-bordered my-2",
          selected: "selectedClass flex flex-wrap",
          tag: "tagClass max-w-full h-fit badge badge-primary badge-outline my-2 mx-1 py-1 px-3 font-light text-sm overflow-wrap-anywhere rounded",
          remove: "removeClass text-error ml-2 w-6",
          suggestions: "suggestionsClass",
          activeSuggestion: "activeSuggestionClass",
          editTagInput: "editTagInputClass",
          editTagInputField: "editTagInputField",
          clearAll: "clearAllClass",
        }}
      />

      {tag && !isDuplicate && (
        <p className="text-xs p-1">
          Press <span className="font-bold text-success">enter</span> to add
        </p>
      )}
      {isDuplicate && (
        <p className="p-1 text-xs text-error">Tag already exists</p>
      )}
    </InputFrame>
  );
};

export default PropertyTags;
// tag: "tagClass max-w-full h-fit	badge badge-primary badge-outline flex m-2 p-3 px-3 text-xs font-light overflow-wrap-anywhere",
// tag: "tagClass max-w-full h-fit	btn btn-primary btn-outline flex text-xs my-2 p-3 font-light overflow-wrap-anywhere",

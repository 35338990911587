import { Link } from "react-router-dom";
import ZescribeLogo from "../../img/ZescribeLogo.png";

const Footer = () => {
  return (
    <footer class="footer  mb-[-1rem] p-10 bg-neutral text-neutral-content">
      <Link to="/" className="text-xl font-bold normal-case">
        <img src={ZescribeLogo} className="w-40" alt="Zescribe Logo" />
      </Link>
      <nav>
        <header class="footer-title">Services</header>
        <a class="link link-hover">Branding</a>
        <a class="link link-hover">Design</a>
        <a class="link link-hover">Marketing</a>
        <a class="link link-hover">Advertisement</a>
      </nav>
      <nav>
        <header class="footer-title">Company</header>
        <a class="link link-hover">About us</a>
        <a class="link link-hover">Contact</a>
        <a class="link link-hover">Jobs</a>
        <a class="link link-hover">Press kit</a>
      </nav>
      <nav>
        <header class="footer-title">Legal</header>
        <a class="link link-hover">Terms of use</a>
        <a class="link link-hover">Privacy policy</a>
        <a class="link link-hover">Cookie policy</a>
      </nav>
    </footer>
  );
};
export default Footer;

import { useState } from "react";
import { Button, Form } from "react-daisyui";

import ButtonGroup from "./button/ButtonGroup";
import SubmitButton from "./button/SubmitButton";
import PropertyInput from "./input/PropertyInput";
import CopyIcon from "../../img/CopyIcon.js";
import PropertyTags from "./input/PropertyTags";
import CardFrame from "../card/Card";

const DirectPropertyForm = () => {
  const [inputField, setInputField] = useState({
    number_of_bedrooms_options: "1",
    number_of_bathrooms_options: "1",
  });

  const [description, setDescription] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isCopied, setIsCopied] = useState(false);

  const inputsHandler = (e) => {
    console.log(e);
    console.log(inputField);
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };

  const submitButton = (e) => {
    e.preventDefault();
    setIsLoading(true);

    console.log(inputField);
    const prompt = generatePrompt(inputField);

    console.log(prompt);
    generateDescription(prompt);
  };

  const copyButton = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(description);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, "800");
  };

  function generatePrompt(inputField) {
    const {
      address,
      number_of_bedrooms_options,
      number_of_bathrooms_options,
      square_feet,
      finished_basement,
      deck,
      additional_information,
    } = inputField;

    const deckText = deck ? ", a deck" : "";
    const basementText = finished_basement ? ", a finished basement" : "";
    console.log(additional_information);

    let prompt = `Write a descriptive property listing description for ${address} 
    which has ${number_of_bedrooms_options} bedrooms, ${number_of_bathrooms_options} bathrooms${deckText} ${basementText}, and ${square_feet} square feet. `;

    if (additional_information) {
      prompt += "Other features of the property are: " + additional_information;
    }

    prompt = prompt.replace(/\n|\r/g, "");

    return prompt;
  }

  const generateDescription = async (prompt) => {
    const response = await queryOpenAI({
      prompt: prompt,
    });
    console.log(response);
    setDescription(response);
    setIsLoading(false);
  };

  const DEFAULT_PARAMS = {
    model: "text-davinci-003",
    temperature: 0.7,
    max_tokens: 512,
  };

  async function queryOpenAI(params = {}) {
    const params_ = { ...DEFAULT_PARAMS, ...params };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(process.env.REACT_APP_OPENAI_API_KEY),
      },
      body: JSON.stringify(params_),
    };
    const response = await fetch(
      "https://api.openai.com/v1/completions",
      requestOptions
    );
    const data = await response.json();
    return data.choices[0].text;
  }

  return (
    <CardFrame className="flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
      <Form onSubmit={submitButton}>
        <PropertyInput name="Address" onChange={inputsHandler} />
        <ButtonGroup name="Number of Bedrooms" onChange={inputsHandler} />
        <ButtonGroup name="Number of Bathrooms" onChange={inputsHandler} />
        <PropertyInput
          name="Square Feet"
          type="number"
          onChange={inputsHandler}
        />
        <PropertyInput
          name="Nearest Shopping Center (miles)"
          type="number"
          onChange={inputsHandler}
        />
        <PropertyInput
          name="Finished Basement"
          type="checkbox"
          className="toggle"
          onChange={inputsHandler}
        />
        <PropertyInput
          name="Deck"
          type="checkbox"
          className="toggle"
          onChange={inputsHandler}
        />
        <PropertyTags name="Additional Information" onChange={inputsHandler} />
        {/* <PropertyTextArea
            name="Additional Information"
            onChange={inputsHandler}
          /> */}
        <SubmitButton className={`${isLoading && "loading"}`}>
          Generate Zescription
        </SubmitButton>
        {description && (
          <>
            <p className="my-6 font-light">{description}</p>
            <Button
              className="border"
              size="sm"
              color={`${isCopied ? "success" : "ghost"}`}
              endIcon={!isCopied && <CopyIcon size="20px" />}
              onClick={(e) => copyButton(e)}
            >
              {isCopied ? "copied!" : "copy"}
            </Button>
          </>
        )}
      </Form>
    </CardFrame>
  );
};

export default DirectPropertyForm;

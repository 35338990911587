import { Button } from "react-daisyui";
import CardFrame from "../../components/card/Card";
import HeaderText from "../../components/header/Header";
import CenterFrame from "../../components/layout/CenterFrame";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { decodeToken } from "react-jwt";

const Account = () => {
  const jwtCookie = localStorage.getItem("jwt");
  const hasJwtCookie = jwtCookie ? true : false;
  const decodedJwt = JSON.parse(localStorage.getItem("decodedJwt"));
  const isPremium =
    (hasJwtCookie && decodedJwt?.user?.membership === "premium") || false;

  const [generationsRemaining, setGenerationsRemaining] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const membership = searchParams.get("membership");
  const stripeCheckoutStatus = searchParams.get("stripeCheckoutStatus");
  const stripeSessionId = searchParams.get("stripeSessionId");

  useEffect(() => {
    const stripeCheckoutWasSuccessful =
      stripeCheckoutStatus && stripeCheckoutStatus === "success";
    const membershipFromStripeIsPremium = membership === "premium";
    const isAlreadyPremium = isPremium;

    const shouldUpgradeUser =
      stripeCheckoutWasSuccessful &&
      membershipFromStripeIsPremium &&
      stripeSessionId &&
      !isAlreadyPremium;

    if (shouldUpgradeUser) {
      upgradeUser()
        .then(function (response) {
          console.log(response);
          // TODO: make generic
          const jwtCookie = response.data.data.access_token;
          const decodedJwtCookie = decodeToken(jwtCookie);

          localStorage.setItem("jwt", jwtCookie);
          localStorage.setItem("decodedJwt", JSON.stringify(decodedJwtCookie));
          // navigate("/");
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
          const errorResponse = error.response?.data;

          if (errorResponse.error) {
            setErrorMessage(errorResponse.error);
            // if (errorResponse.msg === "Not enough segments") {
            //   localStorage.clear("jwt");
            //   window.location.reload();
            // }
          } else {
            setErrorMessage(errorResponse);
          }
        })
        .finally(() => {
          setTimeout(() => {
            console.log("Delayed for 1 second.");
          }, "1000");
        });
    }
  }, [stripeCheckoutStatus, stripeSessionId]);

  useEffect(() => {
    getUserProfile()
      .then(function (response) {
        console.log(response.data.data);
        setGenerationsRemaining(response.data.data.generationsRemaining);
      })
      .catch(function (error) {
        console.log(error);
        const errorResponse = error.response?.data;

        if (errorResponse.error) {
          setErrorMessage(errorResponse.error);
        } else {
          setErrorMessage(errorResponse);
        }
      });
  }, []);

  function getUserProfile() {
    const jwtCookie = localStorage.getItem("jwt");

    const headers = {
      headers: {
        Authorization: `Bearer ${jwtCookie}`,
      },
    };
    return axios.get(`${process.env.REACT_APP_HOST}/api/v1/user`, headers);
  }

  const headerText = membership
    ? `Welcome to your ${membership} account! 🎉`
    : "Your account";

  const upgradeUser = () => {
    const payload = {
      stripeSessionId: stripeSessionId,
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${jwtCookie}`,
      },
    };
    return axios.post(
      `${process.env.REACT_APP_HOST}/api/v1/payment/upgrade-user`,
      payload,
      headers
    );
  };

  const manageAccountButtonOnClick = () => {
    if (!hasJwtCookie) {
      navigate("/");
    }

    setIsLoading(true);
    setErrorMessage("");

    createCustomerPortal()
      .then(function (response) {
        console.log(response);
        window.location.href = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
        const errorResponse = error.response?.data;

        if (errorResponse.error) {
          setErrorMessage(errorResponse.error);
          if (errorResponse.msg === "Not enough segments") {
            localStorage.clear("jwt");
            window.location.reload();
          }
        } else {
          setErrorMessage(errorResponse);
        }
      })
      .finally(() => {
        setTimeout(() => {
          console.log("Delayed for 1 second.");
          setIsLoading(false);
        }, "1000");
      });
  };

  const createCustomerPortal = () => {
    const payload = {};
    const headers = {
      headers: {
        Authorization: `Bearer ${jwtCookie}`,
      },
    };
    return axios.post(
      `${process.env.REACT_APP_HOST}/api/v1/payment/create-portal-session`,
      payload,
      headers
    );
  };

  return (
    <CenterFrame id="pricing">
      <HeaderText title={headerText} subtitle="" />
      <div className="flex justify-center flex-wrap w-full">
        <CardFrame className="mx-4 max-w-xs">
          <p>Membership: {membership || decodedJwt?.user?.membership}</p>
          <p>Generations Remaining: {generationsRemaining}</p>
          <Button
            className={`my-4 btn-primary ${isLoading && "loading"}`}
            onClick={manageAccountButtonOnClick}
          >
            Manage your account
          </Button>
          {errorMessage && <p className="text-error">{errorMessage}</p>}
        </CardFrame>
      </div>
    </CenterFrame>
  );
};

export default Account;

import { useState } from "react";
import { Button } from "react-daisyui";

import PropertyForm from "../../components/form/PropertyForm";
import HeaderText from "../../components/header/Header";
import CenterFrame from "../../components/layout/CenterFrame";
import TabGroup from "../../components/tab/TabGroup";

import CardFrame from "../../components/card/Card";
import CopyIcon from "../../img/CopyIcon";
import { convertToUnderScoreVariable } from "../../utils/ConvertToUnderScoreVariable";
import AutoForms from "../../components/form/AutoForms";

const GenerateZescription = () => {
  const tabs = ["Auto", "Manual"];
  const [activeTab, setActiveTab] = useState(
    convertToUnderScoreVariable(tabs[0])
  );

  const [description, setDescription] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const copyButton = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(description);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, "800");
  };

  return (
    <div className="bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]">
      <CenterFrame id="property-form">
        <HeaderText
          title="Generate Zescription"
          subtitle="Let our AI technology generate a property listing description for you!"
        />
      </CenterFrame>

      <div className="flex justify-center flex-wrap items-start gap-8">
        <CenterFrame className="w-96 flex flex-col items-center">
          <TabGroup
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            size="lg"
          />
          {/* TODO: make code from ZillowForm and PropertyForm shared */}

          {activeTab === convertToUnderScoreVariable(tabs[0]) && (
            <AutoForms
              description={description}
              setDescription={setDescription}
            />
          )}
          {activeTab === convertToUnderScoreVariable(tabs[1]) && (
            <PropertyForm
              description={description}
              setDescription={setDescription}
            />
          )}
        </CenterFrame>
        <CenterFrame className="w-96 flex flex-col items-center">
          {/* h-12 to match TabGroup height */}
          <h1 className="text-xl font-bold h-12 inline-flex items-center">
            Generated Zescription
          </h1>

          <CardFrame className="min-h-[192px]">
            {description && (
              <>
                <p className="my-6 font-light">{description}</p>
                {/* TODO: make shared component for copy button */}
                <Button
                  className="border"
                  size="sm"
                  color={`${isCopied ? "success" : "ghost"}`}
                  endIcon={!isCopied && <CopyIcon size="20px" />}
                  onClick={(e) => copyButton(e)}
                >
                  {isCopied ? "copied!" : "copy"}
                </Button>
              </>
            )}
            {!description && (
              <p className="text-center font-light">
                A generated property listing will appear here once input is
                provided and processed.
              </p>
            )}
          </CardFrame>
        </CenterFrame>
      </div>
    </div>
  );
};

export default GenerateZescription;

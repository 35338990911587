import { Button } from "react-daisyui";
import DirectPropertyForm from "../../components/form/DirectPropertyForm";
import HeaderText, { HeaderLogo } from "../../components/header/Header";
import CenterFrame from "../../components/layout/CenterFrame";
import ZescribeLogo from "../../img/ZescribeLogo.png";

const DirectGenerateZescriptionPage = () => {
  return (
    <div>
      <div className="hero bg-base-200">
        <div className="hero-content text-center">
          <div className="max-w-md p-6">
            <HeaderLogo
              src={ZescribeLogo}
              alt="ZescribeLogo"
              subtitle="Automate your property listing process with Zescribe's AI-powered tools!"
            />
            <Button href="#property-form">Get Started</Button>
          </div>
        </div>
      </div>

      <CenterFrame id="property-form">
        <HeaderText
          title="Generate Zescription"
          subtitle="Let our AI technology generate a property listing description for you!"
        />

        <Button color="primary">Import from Zillow</Button>
        <h1 className="text-2xl font-bold my-6">or</h1>
        {/* TODO: modularize DirectGenerateZescriptionPage and DirectPropertyForm */}
        <DirectPropertyForm />
      </CenterFrame>
    </div>
  );
};

export default DirectGenerateZescriptionPage;

import { Card } from "react-daisyui";

const CardFrame = ({ children, className }) => {
  return (
    <Card
      className={`flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100 mb-12 ${className}`}
    >
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};

export default CardFrame;

import axios from "axios";
import { useState } from "react";
import { Form } from "react-daisyui";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { decodeToken } from "react-jwt";
import CardFrame from "../../components/card/Card";
import SubmitButton from "../../components/form/button/SubmitButton";
import PropertyInput from "../../components/form/input/PropertyInput";
import CenterFrame from "../../components/layout/CenterFrame";

const AuthForm = (props) => {
  return (
    <CenterFrame>
      <RawAuthForm {...props} />
    </CenterFrame>
  );
};

export default AuthForm;

export const RawAuthForm = ({ type = "login", email = "" }) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: email,
    },
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = (data, e) => {
    e.preventDefault();
    setIsLoading(true);

    authenticateUser(data.email, data.password)
      .then(function (response) {
        console.log(response);
        const jwtCookie = response.data.data.access_token;
        const decodedJwtCookie = decodeToken(jwtCookie);
        console.log(jwtCookie);
        localStorage.setItem("jwt", jwtCookie);
        localStorage.setItem("decodedJwt", JSON.stringify(decodedJwtCookie));
        navigate("/");
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        setErrorMessage(error.response.data.error);
      })
      .finally(() => {
        setTimeout(() => {
          console.log("Delayed for 1 second.");
          setIsLoading(false);
        }, "1000");
      });
  };

  function authenticateUser(email, password) {
    if (!new Set(["login", "register"]).has(type))
      console.error("Invalid authentication type.");

    return axios.post(`${process.env.REACT_APP_HOST}/api/v1/auth/${type}`, {
      email: email,
      password: password,
    });
  }

  return (
    <CardFrame className="flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
      <h1 className="text-5xl font-bold my-4">{type}</h1>

      <Form onSubmit={handleSubmit(submitForm)}>
        <PropertyInput
          name="Email"
          register={register}
          error={errors.email}
          regex={{
            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            message: "is invalid",
          }}
        />

        <PropertyInput
          name="Password"
          type="password"
          register={register}
          error={errors.password}
        />

        <SubmitButton loading={isLoading}>{type}</SubmitButton>
        {errorMessage && <p className="text-error">{errorMessage}</p>}
      </Form>
    </CardFrame>
  );
};

import { useState } from "react";
import { Form } from "react-daisyui";

import ButtonGroup from "./button/ButtonGroup";
import SubmitButton from "./button/SubmitButton";
import PropertyInput from "./input/PropertyInput";
import PropertyTags from "./input/PropertyTags";
import CardFrame from "../card/Card";
import { useForm } from "react-hook-form";
import axios from "axios";

const PropertyForm = ({ description, setDescription }) => {
  const [inputField, setInputField] = useState({
    number_of_bedrooms_options: "1",
    number_of_bathrooms_options: "1",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const inputsHandler = (e) => {
    console.log(e);
    console.log(inputField);
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };

  const submitForm = (data, e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    generateDescription(data)
      .then(function (response) {
        console.log(response);
        setDescription(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        const errorResponse = error.response?.data;

        if (errorResponse.error) {
          setErrorMessage(errorResponse.error);
          if (errorResponse.msg === "Not enough segments") {
            console.log("test");
            localStorage.clear("jwt");
            window.location.reload();
          }
        } else {
          setErrorMessage(errorResponse);
        }
      })
      .finally(() => {
        setTimeout(() => {
          console.log("Delayed for 1 second.");
          setIsLoading(false);
        }, "1000");
      });
  };

  function generateDescription(data) {
    const jwtCookie = localStorage.getItem("jwt");
    const payload = {
      prompt: generatePrompt(data),
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${jwtCookie}`,
      },
    };
    return axios.post(
      `${process.env.REACT_APP_HOST}/api/v1/generate/manual`,
      payload,
      headers
    );
  }

  // const submitForm = (data, e) => {
  //   e.preventDefault();
  //   setIsLoading(true);

  //   console.log(data);
  //   const prompt = generatePrompt(data);

  //   console.log(prompt);
  //   generateDescription(prompt);
  // };

  // TODO: move this prompt generation to the server side to conceal generation magic
  function generatePrompt(data) {
    const { address, square_feet, finished_basement, deck } = data;

    const {
      number_of_bedrooms_options,
      number_of_bathrooms_options,
      additional_information,
    } = inputField;

    const deckText = deck ? ", a deck" : "";
    const basementText = finished_basement ? ", a finished basement" : "";
    console.log(additional_information);

    let prompt = `Write a descriptive property listing description for ${address} 
    which has ${number_of_bedrooms_options} bedrooms, ${number_of_bathrooms_options} bathrooms${deckText} ${basementText}, and ${square_feet} square feet. `;

    if (additional_information) {
      prompt += "Other features of the property are: " + additional_information;
    }

    prompt = prompt.replace(/\n|\r/g, "");

    return prompt;
  }

  return (
    <CardFrame className="flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
      <Form onSubmit={handleSubmit(submitForm)}>
        <PropertyInput
          name="Address"
          register={register}
          error={errors.address}
        />
        <ButtonGroup
          name="Number of Bedrooms"
          onChange={inputsHandler}
          register={register}
          error={errors.number_of_bedrooms_options}
        />
        <ButtonGroup
          name="Number of Bathrooms"
          onChange={inputsHandler}
          register={register}
          error={errors.number_of_bathrooms_options}
        />
        <PropertyInput
          name="Square Feet"
          type="number"
          register={register}
          error={errors.square_feet}
        />
        <PropertyInput
          name="Nearest Shopping Center (miles)"
          type="number"
          register={register}
          required={false}
        />
        <PropertyInput
          name="Finished Basement"
          type="checkbox"
          className="toggle"
          register={register}
          required={false}
        />
        <PropertyInput
          name="Deck"
          type="checkbox"
          className="toggle"
          register={register}
          required={false}
        />
        <PropertyTags
          name="Additional Information"
          onChange={inputsHandler}
          register={register}
          required={false}
        />
        {/* <PropertyTextArea
            name="Additional Information"
            onChange={inputsHandler}
          /> */}
        <SubmitButton className={`${isLoading && "loading"}`}>
          Generate Zescription
        </SubmitButton>
        {errorMessage && <p className="text-error">{errorMessage}</p>}
      </Form>
    </CardFrame>
  );
};

export default PropertyForm;

import AuthForm from "./AuthForm";
import { useLocation } from "react-router";

const RegisterForm = () => {
  let data = useLocation();
  console.log(data);
  const email = data.state?.email || "";
  console.log(email);
  return <AuthForm type="register" email={email} />;
};

export default RegisterForm;

import { useEffect } from "react";
import { themeChange } from "theme-change";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";

import CustomNavbar from "./components/navbar/Navbar";
import GenerateZescription from "./containers/pages/GenerateZescriptionPage";
import Landing from "./containers/pages/LandingPage";
import Error from "./containers/pages/ErrorPage";
import LoginForm from "./containers/auth/LoginForm";
import RegisterForm from "./containers/auth/RegisterForm";
import DirectGenerateZescriptionPage from "./containers/pages/DirectGenerateZescriptionPage";
import Pricing from "./containers/pages/Pricing";
import Account from "./containers/pages/Account";
import Footer from "./components/footer/Footer";

const jwtCookie = localStorage.getItem("jwt");
const hasJwtCookie = jwtCookie ? true : false;

const AppLayout = () => (
  <>
    <div className="bg-base-100 min-h-screen p-4">
      <CustomNavbar />
      <Outlet />
    </div>
    <Footer />
  </>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        // element: hasJwtCookie ? <GenerateZescription /> : <Landing />,
        element: hasJwtCookie ? <GenerateZescription /> : <Landing />,
      },
      {
        path: "/login",
        element: <LoginForm />,
      },
      {
        path: "/register",
        element: <RegisterForm />,
      },
      {
        path: "/generate",
        element: hasJwtCookie ? <GenerateZescription /> : <LoginForm />,
      },
      {
        path: "/generate-direct",
        element: <DirectGenerateZescriptionPage />,
      },
      {
        path: "/account",
        element: hasJwtCookie ? <Account /> : <LoginForm />,
      },
      {
        path: "/pricing",
        element: <Pricing />,
      },
    ],
  },
]);

const App = () => {
  const jwtCookie = localStorage.getItem("jwt");
  const hasJwtCookie = jwtCookie ? true : false;

  useEffect(() => {
    console.log("App.js loads");
    themeChange(false);
  });

  useEffect(() => {
    if (hasJwtCookie) {
      console.log("validating jwt token");
      const decodedJwtCookie = decodeToken(jwtCookie);
      console.log(decodedJwtCookie);
      const isJwtCookieExpired = isExpired(jwtCookie);

      if (isJwtCookieExpired || decodedJwtCookie === null) {
        localStorage.removeItem("jwt");
      }
    }
  }, [jwtCookie]);

  return (
    <div data-theme={"zescribeLight"}>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;

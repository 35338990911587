import { useEffect, useRef } from "react";
import Marquee from "react-fast-marquee";

import AOS from "aos";
import "aos/dist/aos.css";
import EmailInput from "../../components/form/input/EmailInput";
import HeaderText from "../../components/header/Header";
import { useOnScreen } from "../../utils/CheckOnScreen";
import ZescribeDemo from "../../img/ZescribeDemo.mp4";
import LandingVideo from "../../img/LandingVideo.mp4";
import ZescribeDemoImage from "../../img/ZescribeDemoImage.png";
import ManualEntryExample from "../../img/ManualEntryExample.png";
import EliminateTechStress from "../../img/EliminateTechStress.png";
import EntryOptions from "../../img/EntryOptions.png";
import CustomFeatures from "../../img/CustomFeatures.png";

const Landing = () => {
  const autoPlayVideoRef = useRef();
  const isVisible = useOnScreen(autoPlayVideoRef);

  if (autoPlayVideoRef && autoPlayVideoRef.current) {
    isVisible
      ? autoPlayVideoRef.current.play()
      : autoPlayVideoRef.current.pause();
  }

  const companies = [
    {
      name: "Zillow",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/e/eb/Zillow_logo.svg/2560px-Zillow_logo.svg.png",
    },
    {
      name: "Redfin",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/5/53/Redfin_logo.png",
    },
    {
      name: "OpenAI",
      image:
        "https://cdn.iconscout.com/icon/free/png-256/free-openai-7601069-6138535.png",
    },
    {
      name: "Icon Realty",
      image:
        "https://www.iconrealtyrva.com/wp-content/uploads/2020/03/logo-normal.png",
    },

    {
      name: "Keller Williams",
      image:
        "https://cflare.smarteragent.com/rest/Resizer?url=https%3A%2F%2Fstorage.googleapis.com%2Fattachment-prod-e2ad%2Fbna0u043a98vupp2fuj0&quality=0.8&webp=true&sig_id=69",
    },
    {
      name: "Remax",
      image:
        "https://seeklogo.com/images/R/remax-logo-FA8AF9C472-seeklogo.com.png",
    },
    {
      name: "Samson",
      image:
        "https://global.acceleragent.com/usr/2537702349/1617387062748sp-logo-black-1.png",
    },
    {
      name: "Long and Foster",
      image:
        "https://www.longandfoster.com/images/Uploads/RECos/35009/ContentFiles/lf-logo.png",
    },
  ];

  useEffect(() => {
    AOS.init({ duration: 1200, offset: 200 });
  }, []);

  return (
    <div>
      <div className="h-[800px] mx-[-1rem] mt-[-4rem] mb-[-8rem] flex justify-center items-center inset-0 bg-white bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]">
        <div className="flex flex-row justiy-center flex-wrap">
          <div className="max-w-2xl p-6">
            <div className={`my-12 mx-22 text-center`}>
              <h1 className="text-6xl font-bold">
                <span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
                  Generate Listing
                </span>{" "}
                Descriptions with AI
              </h1>
              <p className="font-light my-2">
                Automate your property listing process with Zescribe's
                AI-powered tools!
              </p>
            </div>

            <EmailInput />
          </div>

          {/* <div className="max-w-2xl w-[450px] p-6 flex flex-col items-start">
            <h1 className="text-3xl font-bold p-4">How it works</h1>
            <StepIconAndText number={1} text="Enter property address" />
            <StepIconAndText number={2} text="Add custom features" />
            <StepIconAndText number={3} text="Get a beautiful description 🎉" />
            <Line />
          </div> */}
        </div>
      </div>

      <div className="flex justify-center">
        <div className="mockup-browser border bg-base-300 w-4/5 max-w-7xl hidden md:flex flex-col">
          <div className="mockup-browser-toolbar">
            <div className="input">https://zescribe.ai</div>
          </div>
          <div className="flex justify-center bg-base-200">
            <video autoPlay loop muted ref={autoPlayVideoRef}>
              <source src={ZescribeDemo} type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="w-4/5 max-w-7xl md:hidden">
          <img src={ZescribeDemoImage} alt={"ZescribeDemoImage"} />
        </div>
      </div>

      {/* <div className="mx-[-1rem] h-32 bg-[#DAD7CD] px-24">
        <Marquee autoFill speed={20}>
          {companies &&
            companies.map((image, index) => (
              <div key={index} className="w-32 h-32 flex-shrink-0 p-4 mx-4">
                <img
                  src={image.image}
                  alt={image.name}
                  className="w-full h-full object-contain"
                />
              </div>
            ))}
        </Marquee>
      </div> */}

      <LandingPageSection
        title="Save time, money, and tech stress."
        text="Let us handle the technology, so you can focus on providing the best experience for your clients!"
        src={EliminateTechStress}
        alt="Zescribe demonstration of manual entry for property description generation"
      />

      <div
        className="flex flex-col items-center justify-center p-8"
        id="property-form"
      >
        <HeaderText
          title="How It Works"
          subtitle="Our easy to use tools will help you save time to focus on helping your clients!"
        />
        <video
          width="750"
          height="500"
          controls
          loop
          muted
          ref={autoPlayVideoRef}
        >
          <source src={LandingVideo} type="video/mp4" />
        </video>
      </div>

      <LandingPageSection
        title="Add automation to your sales"
        text="With just a few clicks, streamline your real estate sales process by letting Zescribe work for you! Zescribe can seamlessly generate property listing descriptions for you in seconds."
        src={ManualEntryExample}
        alt="Zescribe demonstration of manual entry for property description generation"
      />

      <LandingPageSection
        title="Choose how you provide data"
        text="Flexibility is a key component of Zescribe, allowing multiple
        options of how you provide your property data. Simply enter in a
        Zillow link or choose to fine tune the inputs to provide. With our
        expanding options, the possibilities are limitless!"
        src={EntryOptions}
        alt="Zescribe demonstration of Zillow URL entry for property description generation"
        reverse={true}
      />

      <LandingPageSection
        title="Customize highlights about your property"
        text="Let our AI technology generate beautifully articulated descriptions
        that incorporate custom property features that make your listing stand out!"
        src={CustomFeatures}
        alt="Zescribe demonstration of custom property features for property description generation"
      />

      <div className="py-20 mx-[-1rem] flex justify-center items-center bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]">
        <div className="flex flex-row justify-center flex-wrap">
          <div className="max-w-2xl p-6">
            <HeaderText
              title="Try it today for free!"
              subtitle="Automate your property listing process with Zescribe's AI-powered tools!"
              color="primary"
            />
            <div className="flex flex-row justify-center">
              <div className="p-4">✅ 5 free descriptions</div>
              <div className="p-4">✅ No credit card required</div>
              <div className="p-4">✅ Cancel anytime</div>
            </div>
            <EmailInput />
          </div>
        </div>
      </div>
    </div>
  );
};

const StepIconAndText = ({ number, text }) => {
  return (
    <div className="flex flex-row items-center p-4">
      <div class="flex items-center justify-center w-16 h-16 mx-auto bg-base-100 text-base-primary-content border-2 border-base-primary-content/30 rounded-full ">
        <span class="text-xl font-medium ">{` ${number} `}</span>
      </div>
      <h1 className="text-md md:text-xl font-bold p-4">{text}</h1>
    </div>
  );
};

const Line = () => {
  return (
    <div class="flex justify-center w-full hidden md:flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="875"
        height="48"
        viewBox="0 0 875 48"
        fill="none"
      >
        <path
          d="M2 29C20.2154 33.6961 38.9915 35.1324 57.6111 37.5555C80.2065 40.496 102.791 43.3231 125.556 44.5555C163.184 46.5927 201.26 45 238.944 45C312.75 45 385.368 30.7371 458.278 20.6666C495.231 15.5627 532.399 11.6429 569.278 6.11109C589.515 3.07551 609.767 2.09927 630.222 1.99998C655.606 1.87676 681.208 1.11809 706.556 2.44442C739.552 4.17096 772.539 6.75565 805.222 11.5C828 14.8064 850.34 20.2233 873 24"
          stroke="#D4D4D8"
          stroke-width="3"
          stroke-linecap="round"
          stroke-dasharray="1 12"
          class="w-full mx-auto"
        ></path>
      </svg>
    </div>
  );
};

const LandingPageSection = ({ title, text, src, alt, reverse = false }) => {
  return (
    <div
      className={`flex justify-evenly flex-wrap ${
        reverse && "flex-row-reverse"
      } my-24`}
      data-aos="fade-up"
    >
      <div className="flex flex-col max-w-sm">
        <HeaderText title={title} textAlign="initial" />
        <p>{text}</p>
      </div>
      <div className="py-12 max-w-2xl">
        <img src={src} alt={alt} />
      </div>
    </div>
  );
};

export default Landing;

import { useState } from "react";
import { Link } from "react-router-dom";
import PropertyInput from "./PropertyInput";

const EmailInput = () => {
  /* TODO: Refactor into component for the div below */
  const [inputField, setInputField] = useState({
    email: "",
  });

  const inputsHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };

  return (
    <div className="flex justify-center items-center flex-wrap">
      <PropertyInput
        name="Email"
        onChange={inputsHandler}
        className="w-40"
        formLabel={false}
      />

      <Link
        to="/register"
        state={{ email: inputField.email }}
        className="btn btn-primary mx-2"
      >
        Get Started
      </Link>
    </div>
  );
};

export default EmailInput;

import { Button } from "react-daisyui";
import CardFrame from "../../components/card/Card";
import HeaderText from "../../components/header/Header";
import CenterFrame from "../../components/layout/CenterFrame";
import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Pricing = () => {
  const jwtCookie = localStorage.getItem("jwt");
  const hasJwtCookie = jwtCookie ? true : false;
  const decodedJwt = JSON.parse(localStorage.getItem("decodedJwt"));
  const isPremium =
    (hasJwtCookie && decodedJwt?.user?.membership === "premium") || false;

  const freeText = hasJwtCookie ? "We appreciate you :)" : "Get Started Free";
  const premiumText = isPremium ? "Good Choice :)" : "Become Premium";

  const [checkoutSession, setCheckoutSession] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const premiumButtonOnClick = () => {
    if (!hasJwtCookie) {
      navigate("/");
    }

    setIsLoading(true);
    setErrorMessage("");

    createCheckoutSession()
      .then(function (response) {
        console.log(response);
        window.location.href = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
        const errorResponse = error.response?.data;

        if (errorResponse.error) {
          setErrorMessage(errorResponse.error);
          if (errorResponse.msg === "Not enough segments") {
            localStorage.clear("jwt");
            window.location.reload();
          }
        } else {
          setErrorMessage(errorResponse);
        }
      })
      .finally(() => {
        setTimeout(() => {
          console.log("Delayed for 1 second.");
          setIsLoading(false);
        }, "1000");
      });
  };

  function createCheckoutSession() {
    const payload = {};
    const headers = {
      headers: {
        Authorization: `Bearer ${jwtCookie}`,
      },
    };
    return axios.post(
      `${process.env.REACT_APP_HOST}/api/v1/payment/create-checkout-session`,
      payload,
      headers
    );
  }

  return (
    <CenterFrame id="pricing">
      <HeaderText
        title="Priced to help you automate and grow."
        subtitle="Take your usage of AI and automation for your business to the next level!"
      />
      <div className="flex justify-center flex-wrap w-full">
        {/* TODO: refactor to make generic */}
        <CardFrame className="mx-4 max-w-xs">
          <h1 className="text-3xl font-bold my-4">Free</h1>
          <p>Great for testing our services</p>
          <p>Enjoy 5 trial generations</p>
          <p>Limited to 4 feature tags</p>
          <p>No expansion support</p>
          <h1 className="text-5xl font-bold mt-16">$0</h1>
          <p>/ month</p>
          <Link to="/register" className="btn my-4" disabled={hasJwtCookie}>
            {freeText}
          </Link>
        </CardFrame>
        <CardFrame className="mx-4 max-w-xs">
          <h1 className="text-3xl font-bold my-4">Premium</h1>
          <p>Great for adding to a professional workflow</p>
          <p>Enjoy 45 generations per month</p>
          <p>Unlimited feature tags</p>
          <p>Expansion support and a customized experience</p>
          <h1 className="text-5xl font-bold mt-16">$39.99</h1>
          <p>/ month</p>
          <Button
            className={`my-4 btn-primary ${isLoading && "loading"}`}
            disabled={isPremium}
            onClick={premiumButtonOnClick}
          >
            {premiumText}
          </Button>
          {errorMessage && <p className="text-error">{errorMessage}</p>}
        </CardFrame>
      </div>
    </CenterFrame>
  );
};

export default Pricing;

import axios from "axios";
import { useState } from "react";
import { Form, Toggle } from "react-daisyui";
import { useForm } from "react-hook-form";

import SubmitButton from "./button/SubmitButton";
import PropertyInput from "./input/PropertyInput";
import PropertyTags from "./input/PropertyTags";

const AutoForm = ({ setDescription, type = "address" }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [customData, setCustomData] = useState({});
  const [addCustomData, setAddCustomData] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const regexValidationMap = {
    address: /[\w',-\\/.\s]/,
    zillow_url:
      /^[https://]*[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=,]*)$/,
  };

  const fieldsMap = {
    address: {
      name: "Address",
      apiEndpoint: "autoWithAddress",
      payloadName: "address",
    },
    zillow_url: {
      name: "Zillow URL",
      apiEndpoint: "autoWithZillowUrl",
      payloadName: "zillowUrl",
    },
  };

  const toggleCustomData = () => {
    setAddCustomData(!addCustomData);
  };

  const customDataHandler = (e) => {
    console.log(e);
    console.log(customData);
    setCustomData({ ...customData, [e.target.name]: e.target.value });
  };

  const submitButton = (data, e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    generateDescription(data[type])
      .then(function (response) {
        console.log(response);
        setDescription(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        const errorResponse = error.response?.data;

        if (errorResponse.error) {
          setErrorMessage(errorResponse.error);
          if (errorResponse.msg === "Not enough segments") {
            console.log("test");
            localStorage.clear("jwt");
            window.location.reload();
          }
        } else {
          setErrorMessage(errorResponse);
        }
      })
      .finally(() => {
        setTimeout(() => {
          console.log("Delayed for 1 second.");
          setIsLoading(false);
        }, "1000");
      });
  };

  function generateDescription(data) {
    const jwtCookie = localStorage.getItem("jwt");
    const payload = {
      [fieldsMap[type].payloadName]: data,
    };

    console.log(customData.additional_information);
    if (customData && addCustomData) {
      payload.customData = customData.additional_information;
    }

    const headers = {
      headers: {
        Authorization: `Bearer ${jwtCookie}`,
      },
    };
    return axios.post(
      `${process.env.REACT_APP_HOST}/api/v1/generate/${fieldsMap[type].apiEndpoint}`,
      payload,
      headers
    );
  }

  return (
    <>
      <Form onSubmit={handleSubmit(submitButton)}>
        <PropertyInput
          name={fieldsMap[type].name}
          //   formLabel={false}
          register={register}
          error={errors[type]}
          regex={{
            value: regexValidationMap[type] || null,
            message: "is invalid",
          }}
        />

        <div
          className={`bg-base-${
            !addCustomData ? "100" : "200"
          } rounded-lg px-2 my-2`}
        >
          <Form>
            <Form.Label title="Add Custom Information">
              <Toggle onChange={toggleCustomData} className="m-2" />
            </Form.Label>
          </Form>

          <div className={`${!addCustomData && "hidden"}`}>
            <PropertyTags
              name="Additional Information"
              onChange={customDataHandler}
              register={register}
              required={false}
              defaults={false}
            />
          </div>
        </div>
        <SubmitButton className={`btn-primary`} loading={isLoading}>
          Import from {fieldsMap[type].name}
        </SubmitButton>
      </Form>
      {errorMessage && <p className="text-error">{errorMessage}</p>}
    </>
  );
};

export default AutoForm;

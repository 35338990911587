import CardFrame from "../card/Card";
import AutoForm from "./AutoForm";

const AutoForms = ({ setDescription }) => {
  return (
    <CardFrame>
      <AutoForm setDescription={setDescription} type="address" />
      <p className="m-6 text-center text-lg">or</p>
      <AutoForm setDescription={setDescription} type="zillow_url" />
    </CardFrame>
  );
};

export default AutoForms;
